$cyan: #01B0D3;
$black: #242333;
$black-ter: #2D2C40;
$grey: #c8c8c8;
$grey-dark: #56546d;

$link: $cyan;
$background: $black;
$text: #ffffff;
$family-sans-serif: 'Space Grotesk', sans-serif;
$family-monospace: 'Space Mono', monospace;

$corners: 5px;